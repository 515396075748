import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { getValueFromObject } from '@helpers/utils'
import NextError from 'next/error'
import { fetchData } from '@helpers/contentful'
import { STATIC_PAGES } from '@consts/index'
import Head from '@components/Head'
import NotFound from '../404'
import { EmptyObject } from 'types/helpers'
import { PageProps } from '@components/pages/dynamic'
import { getPages, PageHandle } from '@helpers/pages'

const Pages = dynamic(() => import('@components/pages'))

type MetaImage = {
  type: string
  file: {
    url: string
    contentType: string
  }
}

type PageEntry = {
  pageTitle: string
} & {
  seo_description: string
} & PageProps

function getPageTitle(
  page: PageEntry[],
  contentfulId?: string | null,
  pagesHandle?: string
) {
  if (contentfulId) {
    return (page.find(obj => !!obj.pageTitle) || {}).pageTitle
  }

  return (
    STATIC_PAGES.find(staticPage => staticPage.handle === pagesHandle) || {}
  ).title
}

type PageNextProps = {
  pageEntries: PageEntry[]
  handle: string
  title: string
  description: string
  contentfulId: string
  statusCode: number
  metaImage: {
    url: string
    type: string
    width: number | string
    height: number | string
  }
}

const PageNext = ({
  pageEntries,
  handle,
  title,
  description,
  statusCode,
  contentfulId,
  metaImage
}: PageNextProps) => {
  const [previewPageComponents, setPreviewPageComponents] = useState<
    PageEntry[] | null
  >(null)
  const { query } = useRouter()

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = (await fetchData({
          id: contentfulId,
          isPreview: true
        })) as PageEntry[]
        setPreviewPageComponents(response)
      } catch (e) {
        console.error(e, { contentfulId })
      }
    }
    if (contentfulId && query.preview) {
      fetchPage()
    }
  }, [contentfulId, query.preview])

  if (statusCode === 404) {
    return <NotFound />
  }

  if (statusCode !== 200) {
    return <NextError statusCode={statusCode} />
  }

  return (
    <>
      <Head title={title} desc={description} image={metaImage} />
      <Pages
        handle={handle}
        page={previewPageComponents || pageEntries || []}
      />
    </>
  )
}

function pageHandlesManager() {
  let handles: PageHandle[]

  return {
    get: async function () {
      if (!handles) {
        handles = (await getPages()) || []
      }

      return handles
    }
  }
}
const handlesManager = pageHandlesManager()

/* 
  [[...pageHandle]] was added to catch index routes like '/' and 'pages/'
  Next JS docs: https://nextjs.org/docs/pages/building-your-application/routing/dynamic-routes
*/
export async function getStaticPaths() {
  const pages = await handlesManager.get()

  const paths = [...pages, ...STATIC_PAGES].map(page => ({
    params: {
      pageHandle: [page.handle.replace('/', '')]
    }
  }))

  return { paths, fallback: false }
}

export async function getStaticProps({
  params
}: {
  params: { pageHandle: string[] }
}) {
  try {
    const pageHandle = '/' + (params.pageHandle?.[0] || '')
    const pages = await handlesManager.get()

    const contentfulId =
      pages.find(page => page.handle === pageHandle)?.contentfulId || null

    let pageEntries: PageEntry[] = []

    if (contentfulId) {
      pageEntries = (await fetchData({ id: contentfulId })) as PageEntry[]
    }

    // fetch the page Title either from contentful, either from static_page in consts
    const title = getPageTitle(pageEntries, contentfulId, pageHandle)

    const href =
      pageHandle === '/' ? '' : `/pages/${pageHandle?.replace(/^\//, '')}`
    const description =
      (pageEntries.find(obj => !!obj.seo_description) || {}).seo_description ||
      null
    const metaImageEntry = pageEntries.find(obj => obj.type === 'metaImage')
    const metaImage = getValueFromObject<MetaImage['file'] | EmptyObject>(
      metaImageEntry,
      'file',
      {}
    )

    return {
      props: {
        pageEntries,
        contentfulId: contentfulId,
        href,
        title: title || null,
        description,
        metaImage: {
          ...(metaImage?.url && { url: metaImage?.url }),
          ...(metaImage?.contentType && { url: metaImage?.contentType }),
          width: getValueFromObject(metaImage, 'details.image.width', null),
          height: getValueFromObject(metaImage, 'details.image.height', null)
        },
        handle: pageHandle,
        statusCode: 200
      }
    }
  } catch (e) {
    return {
      props: {
        statusCode: 500
      }
    }
  }
}

export default PageNext
