import Head from '@components/Head'
import { HeroBannerProps } from '@components/pages/components/HeroBanner'
import DynamicPages from '@components/pages/dynamic'
import { ProductsBreakdownProps } from '@components/ui/ProductsBreakdown'

const data = [
  {
    title: 'Something’s down',
    displayFor: 'default',
    classes: 'staticpage',
    images: {
      0: {
        title: 'Something’s down',
        subtitle: '404 error: this page does not exist.',
        description:
          'Well this page exists, but the page you’re looking for does not. Unless of course you were looking for our 404 page — in which case you should really be doing something more constructive with your time. Go to the collections page and think about what you’ve done.',
        button: {
          title: 'Shop now button',
          text: 'Shop now',
          uri: '/collections/trousers',
          buttonStyle: 'primary',
          type: 'button'
        },
        desktopLargeImage: {
          title: '404 Page',
          description: 'Used on the 404 Hero banner',
          file: {
            url: '/static/images/page404/404Page_Desktop.jpg',
            details: { image: { width: 2880, height: 1560 } },
            contentType: 'image/jpeg'
          }
        },
        desktopImage: {
          title: '404 Page',
          description: 'Used on the 404 Hero banner',
          file: {
            url: '/static/images/page404/404Page_Desktop.jpg',
            details: { image: { width: 2880, height: 1560 } },
            contentType: 'image/jpeg'
          }
        },
        tabletImage: {
          title: '404 Page',
          description: '',
          file: {
            url: '/static/images/page404/404Page_Tablet.jpg',
            details: { image: { width: 1536, height: 2000 } },
            contentType: 'image/jpeg'
          }
        },
        mobileImage: {
          title: '404 Page',
          description: '',
          file: {
            url: '/static/images/page404/404Page_Mobile.jpg',
            details: { image: { width: 1478, height: 1936 } },
            contentType: 'image/jpeg'
          }
        },
        whitebackground: true,
        horizontalMobileDown: 'center',
        horizontalTabletDown: 'start',
        horizontalDesktopUp: 'start',
        verticalMobileDown: 'end',
        verticalTabletDown: 'center',
        verticalDesktopUp: 'center',
        mobileFillScreen: false,
        mobileGradient: false,
        type: 'carouselImage'
      }
    },
    headerTransparent: false,
    type: 'imageCarousel'
  } as unknown as HeroBannerProps & { type: 'imageCarousel' },
  {
    contentName: 'Product Category BreakDown - Homepage',
    title: 'Take a look around',
    byline: 'Or, if you’re ready to shop',
    categories: {
      0: {
        foregroundColor: '#001E3C',
        title: 'Trousers',
        image: {
          title: 'Trousers',
          description: '',
          file: {
            url: '/static/images/page404/404Page_Product_Breakdown_Trousers.jpeg',
            details: { image: { width: 570, height: 720 } },

            contentType: 'image/jpeg'
          }
        },
        url: '/collections/trousers',
        type: 'cardMinimal'
      },
      1: {
        foregroundColor: '#001E3C',
        title: 'Denim',
        image: {
          title: 'Denim',
          description: '',
          file: {
            url: '/static/images/page404/404Page_Product_Breakdown_Denim.jpeg',
            details: { image: { width: 570, height: 720 } },

            contentType: 'image/jpeg'
          }
        },
        url: '/collections/denim',
        type: 'cardMinimal'
      },
      2: {
        foregroundColor: '#001E3C',
        title: 'Tops',
        image: {
          title: 'Tops',
          description: '',
          file: {
            url: '/static/images/page404/404Page_Product_Breakdown_Tops.jpeg',
            details: { image: { width: 570, height: 720 } },

            contentType: 'image/jpeg'
          }
        },
        url: '/collections/tops',
        type: 'cardMinimal'
      },
      3: {
        foregroundColor: '#001E3C',
        title: 'Shorts',
        image: {
          title: 'Shorts',
          description: '',
          file: {
            url: '/static/images/page404/404Page_Product_Breakdown_Shorts.jpeg',
            details: { image: { width: 570, height: 720 } },

            contentType: 'image/jpeg'
          }
        },
        url: '/collections/shorts',
        type: 'cardMinimal'
      }
    },
    type: 'productBreakdown'
  } as unknown as ProductsBreakdownProps & { type: 'productBreakdown' }
]

function Page() {
  return (
    <>
      <Head
        title="404 Page Not Found"
        desc="This page cannot be found. Error 404"
      />
      <DynamicPages handle="/404" page={data} />
    </>
  )
}

export default Page
